<template>
  <div>
      <form v-on:submit.prevent="bookAppointment(selectedAppointment)">
      <v-row>
        <v-col>
          <h2>Step 4:</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>Let's check your information:</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-simple-table class="elevation-1">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">Location:</td>
                  <td>{{ selectedAppointment.locationName }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Location Dose Type</td>
                  <td><dose-type-chip :doseType="selectedLocation.vaccineDoseType" v-if="selectedLocation.vaccineDoseType"></dose-type-chip></td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Address:</td>
                  <td>{{ selectedAppointment.address }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Time Frame:</td>
                  <td>{{ getPrettyTime(selectedAppointment.datetime_start) }} to {{ getPrettyTime(selectedAppointment.datetime_end) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Vaccine Type</td>
                  <td><manufacturer-chip :manufacturerId="selectedLocation.vaccineManufacturerId" :manufacturerName="selectedLocation.vaccineManufacturerName"  v-if="selectedLocation && selectedLocation.vaccineManufacturerId > 0"></manufacturer-chip></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Enter a phone number where we can reach you:</h3>
          <br/>
            <v-text-field
            :error="phoneError"
            label="Your Phone Number Please"
            v-model="phone"
            v-mask="'(###) ###-####'"
            :rules="required"
            type="tel"
            outlined
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn x-large class="ma-1" style="width: 100%" color="primary" :loading="savingAppointment" :disabled="savingAppointment" @click="bookAppointment(selectedAppointment)"
            >Schedule Appointment!
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn x-large class="ma-1" style="width: 100%" @click="goBack">
            Back
          </v-btn>
        </v-col>
      </v-row>
      </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    selectedLocation: {
      type: Object,
      default: () => {},
      required: true
    },
    selectedAppointment: {
      type: Object,
      default: () => {},
      required: true
    },
    user: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  components: {
    manufacturerChip: () => import('@/module/vaccineManufacturer/components/manufacturerChip'),
    doseTypeChip: () => import('@/module/vaccineManufacturer/components/doseTypeChip')
  },
  watch: {
    phone: function () {
      this.validatePhone()
    },
  },
  computed: {
  },
  data() {
    return {
      phoneError: false,
      phone: '',
      required: [
        v => !!v || 'Required Field'
      ],
      savingAppointment: false,
    };
  },
  methods: {
    ...mapActions('vaccineLocations', ['getAppointmentsByPage']),
    ...mapActions('users', ['insertAppointment']),
    employeeScheduledDate: function () {
      if (this.employeeData && this.employeeData.scheduled_date) {
        return moment(this.employeeData.scheduled_date).format('MMMM Do YYYY, h:mm a')
      }
      return ''
    },
    convertToLocal: function (date) {
      return moment.utc(date).local()
    },
    validatePhone: function () {
      if (!/\(\d{3}\)\s\d{3}-\d{4}/.test(this.phone)) {
        this.phoneError = true
        return false
      } else {
        this.phoneError = false
        return true
      }
    },
    getPrettyTime: function (date) {
      return this.convertToLocal(date).format('MMM Do h:mm a')
    },
    bookAppointment: function (item) {
      if (!this.validatePhone()) {
        return false
      }
      this.savingAppointment = true
      var submission = {
        appointmentBlock: this.selectedAppointment.id,
        scheduledDate: this.selectedAppointment.datetime_start,
        appointment_status: 1,
        phone: this.phone
      }

      this.insertAppointment({ appointment: submission, personId: this.user.personId }).then((response) => {
        this.savingAppointment = false
        this.$emit('bookedAppointment')
      })
    },
    goBack: function () {
      this.$emit('goBack')
    }
  },
  mounted() {
  },
};
</script>
